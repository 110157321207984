import { BarChart2, ShoppingBag, Users, CircleCheckBig } from "lucide-react";
import { motion } from "framer-motion";

import Header from "../components/common/Header";
import StatCard from "../components/common/StatCard";
import SalesOverviewChart from "../components/overview/SalesOverviewChart";
import CategoryDistributionChart from "../components/overview/CategoryDistributionChart";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAdminStats } from "../../redux/slices/adminSlice";
import OrdersTable from "../components/orders/OrdersTable";
import UsersTable from "../components/users/UsersTable";

const OverviewPage = () => {
	const stats = useSelector((state) => state.adminData.stats.data);
	const loading = useSelector((state) => state.adminData.stats.loading);

	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(fetchAdminStats())
	}, [dispatch]);


	return (
		<div className='flex-1 overflow-auto relative z-0'>
			<Header title='Overview' className="sticky top-0 z-20 bg-white shadow-md" />
			<main className='max-w-7xl mx-auto py-6 px-4 lg:px-8'>
				{loading && (
					<div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50 rounded-xl">
						<p className="text-black z-50">Loading...</p>
					</div>
				)}
				{/* STATS */}
				<motion.div
					className='grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8'
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 1 }}
				>
					<StatCard name='Total Users' icon={Users} value={stats?.userCount} color='#8B5CF6' />
					<StatCard name='Total Products' icon={ShoppingBag} value={stats?.productCount} color='#EC4899' />
					<StatCard name='Orders' icon={BarChart2} value={stats?.orderCount} color='#10B981' />
					<StatCard name='Completed Orders' icon={CircleCheckBig} value={`${stats?.completedOrdersCount}`} color='#6366F1' />
				</motion.div>

				{/* CHARTS */}

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8'>
					<SalesOverviewChart />
					<CategoryDistributionChart />
				</div>
				<UsersTable />
				<OrdersTable />
			</main>
		</div>
	);
};
export default OverviewPage;
