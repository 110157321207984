import axios from 'axios';
import serverUrl from '../config/serverUrl';


const API_URL = `${serverUrl}/admin`;
const adminToken = localStorage.getItem('adminToken')
export const addProduct = async (data) => {
    const filteredProduct = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => {
            return (
                value !== undefined &&
                value !== null &&
                !Number.isNaN(value) &&
                (typeof value === 'string' ? value.trim() !== '' : true) &&
                (Array.isArray(value) ? value.filter(item => item !== undefined).length > 0 : true)
            );
        })
    );
    try {
        const response = await axios.post(`${API_URL}/products`, [filteredProduct], {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        throw error.response.data
    }
}

export const deleteProduct = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/products/${id}`, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        throw error.response.data
    }
}


export const updateProduct = async (id, data) => {
    const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined && value !== 0)
    );
    try {
        const response = await axios.patch(`${API_URL}/products/${id}`, filteredData, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const createOffer = async (data) => {

    const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined && value !== 0)
    );

    try {
        const response = await axios.post(`${API_URL}/offer`, filteredData, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        // console.log(response.data)
        return response.data;
    } catch (error) {
        console.log(error)
        throw error.response.data
    }
}

export const getOffer = async () => {

    try {
        const response = await axios.get(`${API_URL}/offer`, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        throw error.response.data
    }
}