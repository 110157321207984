import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { updateProduct } from '../../../services/adminProducts';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { fetchAllProducts } from '../../../redux/slices/productSlice';

const UpdateProductModal = ({ isOpen, page, onClose, product }) => {
    const dispatch = useDispatch()
    const [updatedProduct, setUpdatedProduct] = useState({
        productName: '',
        inStock: false,
        price: '',
        size: [],
        description: '',
        color: ''
    });

    useEffect(() => {
        if (product) {
            setUpdatedProduct({
                productName: product.productName || '',
                inStock: product.inStock || false,
                price: product.price || '',
                size: product.size || '',
                color: product.color || '',
                description: product.description || ''
            });
        }
    }, [product]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUpdatedProduct((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked :
                name === 'price' ? parseFloat(value) || 0 :
                    name === 'size' ? (value.length > 0 ? value.split(',').map(item => item.trim()) : []) :
                        value,
        }));
    };


    const handleSubmit = async () => {
        try {
            await toast.promise(
                updateProduct(product.id, updatedProduct),
                {
                    loading: 'Updating Product...',
                    success: (response) => `${response.message}`,
                    error: (err) => `${err.response.data.message}`,
                }
            )
            dispatch(fetchAllProducts({ page }));
            onClose();
        } catch (error) {
            console.log(error)
        }
    };

    if (!isOpen) return null;

    return (
        <motion.div>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
                    <h2 className="text-2xl font-semibold mb-4">Product Update Info</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium">Product Name:</label>
                            <input
                                type="text"
                                name="productName"
                                value={updatedProduct.productName}
                                onChange={handleChange}
                                placeholder="Product Name"
                                className="mt-1 p-2 border rounded w-full"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Stocks:</label>
                            <input
                                type="text"
                                name="color"
                                value={updatedProduct.color}
                                onChange={handleChange}
                                placeholder="Color"
                                className="mt-1 p-2 border rounded w-full"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Price:</label>
                            <input
                                type="text"
                                name="price"
                                value={updatedProduct.price}
                                onChange={handleChange}
                                placeholder="Price"
                                className="mt-1 p-2 border rounded w-full"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Size:</label>
                            <input
                                type="text"
                                name="size"
                                value={updatedProduct.size}
                                onChange={handleChange}
                                placeholder="Size"
                                className="mt-1 p-2 border rounded w-full"
                            />
                        </div>
                        <div className="flex items-center mt-4">
                            <input
                                type="checkbox"
                                name="inStock"
                                id='inStock'
                                checked={updatedProduct.inStock}
                                onChange={handleChange}
                                c className="w-5 h-5 mr-2 accent-[#754F23] rounded"
                            />
                            <label htmlFor='inStock' className="text-sm font-medium">In Stock</label>
                        </div>
                    </div>
                    <h2 className="text-2xl font-semibold mt-6 mb-4">Product Description</h2>
                    <div className="grid grid-cols-1 gap-4">
                        <div>
                            <label className="block text-sm font-medium">Product Description:</label>
                            <textarea
                                name="description"
                                value={updatedProduct.description}
                                onChange={handleChange}
                                placeholder="Product Description..."
                                rows="10"
                                className='mt-1 p-2 border rounded w-full'
                            />
                        </div>
                    </div>

                    <div className="mt-6 flex justify-end">
                        <button
                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-4 py-3 bg-[#754F23] hover:opacity-80 text-white rounded justify-start items-start gap-4 inline-flex text-sm font-semibold"
                            onClick={handleSubmit}
                        >
                            Save Product
                        </button>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default UpdateProductModal;
