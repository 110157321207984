import React from 'react';
import Breadcrumbs from '../../shared/Breadcrumbs';

const TermsAndConditions = () => {
    return (
        <div className="max-w-6xl mb-[100px] px-2 sm:px-0 mx-auto py-8">
            <div className=" sm:my-[20px]">
                <Breadcrumbs />
            </div>
            <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
            <h2 className="text-2xl font-semibold mt-4 mb-2">1. Introduction</h2>
            <p className="mb-4">
                Welcome to <strong> Humaira Abayas</strong>. These terms and conditions govern your use of our website, products, and services. By accessing or using the site, you agree to comply with these terms. If you do not agree with these terms, please refrain from using our site.
            </p>

            <h2 className="text-2xl font-semibold mt-4 mb-2">2. Changes to Terms and Conditions</h2>
            <p className="mb-4">
                Humaira Abayas reserves the right to modify or update these terms at any time. Any changes will be reflected on this page with a revised date. It is your responsibility to review these terms regularly.
            </p>

            <h2 className="text-2xl font-semibold mt-4 mb-2">3. Products and Services</h2>
            <ul className=' space-y-2 mb-5'>
                <li>
                    <p className="mb-4">
                        <strong>● Product Availability</strong>:
                        All products offered on Humaira Abayas are subject to availability. In the event of a product being out of stock, we will notify you as soon as possible.
                    </p>
                </li>
                <li>
                    <p className="mb-4">
                        <strong>● Pricing</strong>:
                        Prices for products are listed in INR. We reserve the right to modify product prices at any time without prior notice.
                    </p>
                </li>
                <li>
                    <p className="mb-4">
                        <strong>● Product Information</strong>:
                        We make every effort to accurately display product information, including descriptions, prices, and images. However, we cannot guarantee that all information is error-free.
                    </p>
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-4 mb-2">4. Account Registration</h2>
            <p className="mb-4">
                To place an order, you may need to create an account on our website. You agree to provide accurate and complete information when creating an account, and to keep your account information up to date.
            </p>


            <h2 className="text-2xl font-semibold mt-4 mb-2">5. Orders and Payments</h2>
            <ul className=' space-y-2 mb-5'>
                <li>
                    <p className="mb-4">
                        <strong>● Order Acceptance</strong>:
                        Orders placed through the Humaira Abayas website are subject to acceptance by us. We reserve the right to refuse or cancel any order at our discretion.                    </p>
                </li>
                <li>
                    <p className="mb-4">
                        <strong>● Payment Methods</strong>:
                        We accept payments through various methods, including credit/debit cards, PayPal, and other secure payment gateways.
                    </p>
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-4 mb-2">6. Shipping and Delivery</h2>
            <ul className=' space-y-2 mb-5'>
                <li>
                    <p className="mb-4">
                        <strong>● Shipping</strong>:
                        We offer shipping to various locations. Shipping costs and delivery times will vary depending on the destination and chosen shipping method.                    </p>
                </li>
                <li>
                    <p className="mb-4">
                        <strong>● Delivery Issues</strong>:
                        While we strive to deliver orders on time, Humaira Abayas is not responsible for delays caused by third-party shipping carriers or unforeseen circumstances.                    </p>
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-4 mb-2">7. Returns and Refunds</h2>
            <ul className=' space-y-2  mb-5'>
                <li>
                    <p className="mb-4">
                        <strong>● Returns for Damaged Products</strong>:
                        If you receive a damaged product, we will accept returns only if the issue is clearly documented. To qualify for a return or exchange, you must provide a valid video of the unboxing process showing the damage to the product. The video should clearly capture the condition of the product as it was received, as well as any visible defects or damage.
                        </p>
                </li>
                <li>
                    <p className="mb-4">
                        <strong>● Exchange Process</strong>:
                        If the product is damaged and your return request is approved, we will offer you an <strong>exchange</strong> for the same item or a similar item of equal value, depending on availability. You will need to return the damaged product in its original condition and packaging.                    </p>
                </li>
                <li>
                    <p className="mb-4">
                        <strong>● No Refunds</strong>:
                        Please note that <strong>no refunds</strong> will be provided under any circumstances. We only offer exchanges for damaged products as outlined above.                    </p>
                </li>
                <li>
                    <p className="mb-4">
                        <strong>● Return Eligibility</strong>:
                        Returns for any reason other than damage, including change of mind, will not be accepted. We urge you to check your order carefully before confirming the purchase.                    </p>
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-4 mb-2">8. Intellectual Property</h2>
            <p className="mb-4">
                All content on the Humaira Abayas website, including logos, designs, text, images, and other materials, is protected by intellectual property laws. You may not use or reproduce any content without our express written consent.
            </p>

            <h2 className="text-2xl font-semibold mt-4 mb-2">9. Limitation of Liability</h2>
            <p className="mb-4">
                Humaira Abayas is not liable for any direct, indirect, incidental, or consequential damages arising from your use of our website, products, or services.
            </p>

            <h2 className="text-2xl font-semibold mt-4 mb-2">10. Governing Law</h2>
            <p className="mb-4">
                These terms and conditions are governed by the laws of India. Any disputes will be resolved in the courts of Hyderabad.
            </p>

        </div>
    );
};

export default TermsAndConditions;
