import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import UserProfileLayout from '../../shared/UserProfileLayout';
import AddressBook from './user_profile_component/AddressBook';
import MyOrders from './user_profile_component/MyOrders';
import MyProfile from './user_profile_component/MyProfile';
import OrderDetailsPage from './user_profile_component/order_component/OrderDetails';

const UserProfile = () => {
    return (
        <Routes>
            <Route path="*" element={<UserProfileLayout />}>
                <Route path="" element={<Navigate to="profile" replace />} />
                <Route path="profile" element={<MyProfile />} />
                <Route path="orders" element={<MyOrders />} />
                <Route path="address-book" element={<AddressBook />} />
                <Route path="orders/details/:id" element={<OrderDetailsPage />} />
            </Route>
        </Routes>
    );
};

export default UserProfile;
