import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Search } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../../redux/slices/adminSlice";


const UsersTable = () => {
	const dispatch = useDispatch();
	const users = useSelector((state) => state.adminData.users.data);
	const loading = useSelector((state) => state.adminData.users.loading);
	const [page, setPage] = useState(0);

	const [searchTerm, setSearchTerm] = useState("");
	const [filteredUsers, setFilteredUsers] = useState(users);



	useEffect(() => {
		if (searchTerm === "") {
			setFilteredUsers(users);
		} else {
			setFilteredUsers(
				users.filter(
					(order) =>
						order?.id?.toLowerCase().includes(searchTerm) ||
						order?.shippingAddress?.firstName?.toLowerCase().includes(searchTerm)
				)
			);
		}
	}, [users, searchTerm]);

	useEffect(() => {
		dispatch(fetchUsers(page))
	}, [dispatch, page]);


	const handleSearch = (e) => {
		setSearchTerm(e.target.value.toLowerCase());
	};


	const handleNext = async () => {
		try {
			setPage((prevPage) => prevPage + 1);
			// await dispatch(fetchUsers(page + 1));
		} catch (error) {
			console.error("Error loading more products:", error);
		}
	};

	const handlePrev = async () => {
		try {
			if (page > 0) {
				setPage((prevPage) => prevPage - 1);
				// await dispatch(fetchUsers(page - 1));
			}
		} catch (error) {
			console.error("Error loading more products:", error);
		}
	};


	return (
		<motion.div
			className='bg-white backdrop-blur-md shadow-lg rounded-xl p-6 mb-8'
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ delay: 0.2 }}
		>
			{loading && (
				<div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10 rounded-xl">
					<p className="text-gray-700">Loading...</p>
				</div>
			)}
			<div className='flex justify-between items-center mb-6'>
				<h2 className='text-xl font-medium text-black'>Users</h2>
				<div className='relative'>
					<input
						type='text'
						placeholder='Search Users...'
						className='bg-gray-100 border text-black placeholder-gray-400 rounded-lg pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#DB4444]'
						value={searchTerm}
						onChange={handleSearch}
					/>
					<Search className='absolute left-3 top-2.5 text-gray-400' size={18} />
				</div>
			</div>

			<div className='overflow-x-auto h-[500px] overflow-auto no-scrollbar'>
				<table className='min-w-full divide-y divide-gray-700'>
					<thead>
						<tr>
							<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>
								Name
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>
								Email
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>
								Role
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>
								Phone
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>
								Actions
							</th>
						</tr>
					</thead>

					<tbody className='divide-y divide-gray-300'>
						{filteredUsers.map((user) => (
							<motion.tr
								key={user?.id}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ duration: 0.3 }}
							>
								<td className='px-6 py-4 whitespace-nowrap'>
									<div className='flex items-center'>
										<div className='flex-shrink-0 h-10 w-10'>
											<div className='h-10 w-10 rounded-full bg-gradient-to-r from-purple-400 to-blue-500 flex items-center justify-center text-white font-semibold'>
												{user?.firstName?.charAt(0).toUpperCase()}
											</div>
										</div>
										<div className='ml-4'>
											<div className='text-sm font-normal text-[#555f7e]'>{user?.firstName} {user?.lastName}</div>
										</div>
									</div>
								</td>

								<td className='px-6 py-4 whitespace-nowrap'>
									<div className='text-sm text-[#555f7e]'>{user?.email}</div>
								</td>
								<td className='px-6 py-4 whitespace-nowrap'>
									<span className='px-2 inline-flex text-xs leading-5 font-normal rounded-full bg-blue-800 text-blue-100'>
										User
									</span>
								</td>

								<td className='px-6 py-4 whitespace-nowrap'>
									<span
										className={`px-2 inline-flex text-xs leading-5 font-normal rounded-full`}
									>
										{user?.phone || 'N/A'}
									</span>
								</td>

								<td className='px-6 py-4 whitespace-nowrap text-sm text-gray-300'>
									<button className='text-indigo-400 hover:text-indigo-300 mr-2'>Edit</button>
									<button className='text-red-400 hover:text-red-300'>Delete</button>
								</td>
							</motion.tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="gap-2 flex justify-end pt-2 space-x-2">
				<button
					className='bg-red-950 text-white rounded px-2 hover:bg-red-500'
					onClick={handlePrev}
					disabled={page === 0}
				>
					Prev
				</button>
				<button className='bg-red-950 text-white rounded px-2 hover:bg-red-500' onClick={handleNext}>
					Next
				</button>
			</div>
		</motion.div>
	);
};
export default UsersTable;
