import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../home/Header'
import Footer from '../home/Footer'
const Layout = () => {
    return (
        <div>
            <Header />
            <main className="flex-grow min-h-screen mt-32">
                <Outlet />
            </main>
            <Footer />
        </div>
    )
}

export default Layout
