import axios from 'axios';
import serverUrl from '../config/serverUrl';


const API_URL = `${serverUrl}/admin`;


export const getStats = async () => {
    const adminToken = localStorage.getItem('adminToken')
    try {
        const response = await axios.get(`${API_URL}/stats`, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        throw error.response.data
    }
}


export const getOffers = async (page) => {
    const adminToken = localStorage.getItem('adminToken')

    try {
        const response = await axios.get(`${API_URL}/offer`, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        throw error.response.data
    }
}

export const getOffersByProductId = async (id) => {
    const adminToken = localStorage.getItem('adminToken')

    try {
        const response = await axios.get(`${API_URL}/offer/${id}`, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        throw error.response.data
    }
}


export const changeOffersStatusByProductId = async (id, status) => {
    const adminToken = localStorage.getItem('adminToken')

    try {
        const response = await axios.patch(`${API_URL}/offer/${id}?status=${status}`,{}, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        throw error.response.data
    }
}




export const getOrders = async (page) => {
    const adminToken = localStorage.getItem('adminToken')

    try {
        const response = await axios.get(`${API_URL}/order?page=${page}`, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        throw error.response.data
    }
}

export const getOrderById = async (id) => {
    const adminToken = localStorage.getItem('adminToken')

    try {
        const response = await axios.get(`${API_URL}/order/${id}`, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        throw error.response.data
    }
}

export const updateOrderStatus = async (id, status) => {
    const adminToken = localStorage.getItem('adminToken')
    try {
        const response = await axios.patch(`${API_URL}/order/${id}?status=${status}`, {}, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        throw error
    }
}

export const getProducts = async () => {
    try {
        const response = await axios.get(`${API_URL}/products`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error.response.data
    }
}

export const getProductById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/products/${id}`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error.response.data
    }
}


export const getUsers = async (page) => {
    const adminToken = localStorage.getItem('adminToken')
    try {
        const response = await axios.get(`${API_URL}/user?page=${page}`, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        throw error.response.data
    }
}