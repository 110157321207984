import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
// import { TbTruckDelivery } from "react-icons/tb";
// import { HiArrowPath } from "react-icons/hi2";

import { fetchProduct, fetchProductByColor } from '../../redux/slices/productSlice';
import { addItemToCart } from '../../services/cartService';
import Breadcrumbs from '../../shared/Breadcrumbs';
import ProductReviews from '../../shared/StarRating';
import { addToWishlist, getWishlist, removeFromWishlist } from '../../redux/slices/wishListSlice';
import { fetchCartItems } from '../../redux/slices/cartSlice';
import SimilarProductsPage from '../../shared/SimilarProduct';

import 'react-toastify/dist/ReactToastify.css';
import Modals from '../../shared/Modal';
import Login from '../auth/Login';
import Register from '../auth/Register';
import ProductDetailsAndReviews from './review_component/ProductDetailsAndReviews'
import { fetchReview } from '../../redux/slices/reviewSlice';
import ImageModal from '../../shared/ImageModal';


const ProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const products = useSelector((state) => state.products.selectedProduct);
  const loading = useSelector((state) => state.products.selectedProduct.isLoading);
  const error = useSelector((state) => state.products.selectedProduct.error);
  const wishlistItems = useSelector((state) => state.wishlist.wishlistItems.data);
  const wishlistLoading = useSelector((state) => state.wishlist.loading);
  const user = useSelector((state) => state.auth.user);

  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');

  const [quantity, setQuantity] = useState(1);
  const [mainImage, setMainImage] = useState('');

  const [isInWishlist, setIsInWishlist] = useState(false);
  const [, setLocalWishlist] = useState([]);
  const [isAddingToCart, setIsAddingToCart] = useState(false);

  const [open, setOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const switchToRegister = () => setIsLogin(false);
  const switchToLogin = () => setIsLogin(true);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const loadData = async () => {
      await dispatch(fetchProduct(id)).unwrap();
      dispatch(fetchReview(id))
      await dispatch(getWishlist()).then(({ payload }) => {
        setLocalWishlist(payload);
      });
    };
    loadData();
  }, [id, dispatch]);

  useEffect(() => {
    if (products.product?.id) {
      setSelectedColor(products.product.color || '');
      setSelectedSize(products.product.size?.[0] || '');
      setMainImage(products.images?.[0]?.url || '');
      setIsInWishlist(products.product.isLike || false);
    }
  }, [products, wishlistItems, wishlistLoading]);

  if (error || !products.product?.id) return <div className='text-center h-screen font-TenorSans'>Please wait product is loading...</div>;


  const handleAddToCart = async () => {
    if (!user) {
      handleOpen();
      return;
    }

    if (!products.product.inStock) {
      toast.success("Product is not available")
      console.log('Product is not available');
      return;
    }
    try {
      setIsAddingToCart(true);
      const res = await addItemToCart(products.product.id, quantity, selectedSize);
      dispatch(fetchCartItems())
      toast.success(res.message)
    } catch (error) {

      toast.error("Error adding item to cart")
      console.error('Error adding item to cart:', error);
    } finally {
      setIsAddingToCart(false);
    }
  };

  const handleQuantityChange = (amount) => {
    setQuantity(prevQuantity => Math.max(1, prevQuantity + amount));
  };


  const handleWishlistToggle = async () => {

    if (!user) {
      handleOpen();
      return;
    }

    try {
      if (isInWishlist) {
        const res = await dispatch(removeFromWishlist(id)).unwrap();
        await dispatch(getWishlist()).unwrap();
        toast.success(res.payload.message)
      } else {
        const res = await dispatch(addToWishlist(id)).unwrap();
        await dispatch(getWishlist()).unwrap();
        toast.success(res.payload.message)
      }

      const resultAction = await dispatch(fetchProduct(id));
      if (fetchProduct.fulfilled.match(resultAction)) {
        const updatedProduct = resultAction.payload;

        if (updatedProduct && updatedProduct.product) {
          setSelectedColor(updatedProduct.product.color || '');
          setSelectedSize(updatedProduct.product.size?.[0] || '');
          setMainImage(updatedProduct.images?.[0]?.url || '');
          setIsInWishlist(updatedProduct.product.isLike || false);
        }
      } else {
        toast.error('Failed to fetch the updated product')
        console.error('Failed to fetch the updated product');
      }
    } catch (error) {
      toast.error('Error updating wishlist')
      console.error('Error updating wishlist:', error);
    }
  };


  const percentageOffer = products.product.offer.find(o => o.offerType === 'percentOff' && o.percentOff);
  const offerPrice = products.product.offer.find(o => o.offerType === 'offerPrice' && o.offerPrice);

  let finalPrice = products.product.price;
  if (offerPrice) {
    finalPrice = offerPrice.offerPrice;
  } else if (percentageOffer) {
    finalPrice = products.product.price - (products.product.price * (percentageOffer.percentOff / 100));
  }

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    fetchProductDetailsByColor(color);
  };


  const fetchProductDetailsByColor = async (color) => {
    const productName = products.product.productName;
    try {
      const response = await dispatch(fetchProductByColor({ color, productName })).unwrap();
      const updatedProduct = response.payload;
      if (updatedProduct) {
        navigate(`/${updatedProduct.product.categoryId}/${updatedProduct.product.subCategoryId}/${updatedProduct.product.id}`);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.error('Error fetching product details by color:', error);
    }
  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      <ToastContainer />
      {loading && (
        <div className="loading-overlay">
          <div className="text-white font-TenorSans h-screen text-xl">Loading...</div>
        </div>
      )}
      {isModalOpen && (
        <div className=''>
          <div className='image_card'>
            <ImageModal
              isOpen={isModalOpen}
              handleClose={closeModal}
              imageSrc={mainImage}
              altText={products.product.productName}
            />
          </div>
        </div>
      )}

      <div className={`${loading ? 'opacity-50' : ''}`}>
        <div className='xl:mx-14 '>
          <Breadcrumbs productName={products.product.productName} />
        </div>
        <div className="md:flex px-2 font-TenorSans  md:justify-around xl:mx-28 border-black">

          <div className='lg:flex lg:flex-row-reverse md:w-[50%] 2xl:w-[40%]  border-black'>
            <div className="flex-grow">
              <img
                onClick={openModal}
                src={mainImage}
                alt={products.product.productName}
                className="w-full h-[480px]  lg:h-[505px] xl:h-[600px]  2xl:h-[1000px] bg-[#F5F5F5] object-cover"
              />
            </div>
            <div className="flex p-2  mb-2 lg:mb-0 borer w-full border-black lg:h-[505px]  xl:h-[600px] 2xl:h-[1000px] gap-x-2  lg:w-[150px] overflow-x-auto lg:overflow-y-auto no-scrollbar flex-row lg:flex-col items-center">
              {products.images?.map((image, index) => (
                <img
                  key={index}
                  src={image.url}
                  alt={products.product.name}
                  className={`lg:h-[138px] h-[100px] bg-[#F5F5F5] w-[95px] md:w-[140px] lg:w-full object-cover lg:mb-2 cursor-pointer ${mainImage === image.url ? 'border-2 border-[#754F23]' : ''}`}
                  onClick={() => setMainImage(image.url)}
                />
              ))}
            </div>
          </div>


          <div className="flex mx-auto md:w-1/2 w-full  flex-col items-center border-black">
            <div className="w-full md:w-3/4">
              <h1 className="text-3xl">{products.product.productName}</h1>
              <div className=' align-middle py-auto flex gap-3 mb-1'>
                <ProductReviews reviews={products.product.rating} />
                <span className="text-sm my-auto text-gray-500 mt-2 font-Poppins">({products.product.reviewCount} Reviews) |</span>
                <span className={`text-sm my-auto mt-2 font-Poppins ${products.product.inStock ? 'text-green-500' : 'text-red-500'}`}>
                  {products.product.inStock ? 'In Stock' : 'Out of Stock'}
                </span>
              </div>

              {offerPrice ? (
                <div>
                  <p className="text-2xl font-normal font-TenorSans leading-normal mb-[24px] tracking-wide">
                    &#8377;{offerPrice.offerPrice.toFixed(2)}
                    <span className='text-[#808080] text-[15px] line-through ml-2'>&#8377;{products.product.price.toFixed(2)}</span>
                  </p>
                </div>
              ) : (
                <p className="text-2xl font-normal font-TenorSans leading-normal mb-[24px] tracking-wide">
                  &#8377;{finalPrice.toFixed(2)}
                  {products.product.price !== finalPrice && (
                    <span className='text-[#808080] text-[15px] line-through ml-2'>
                      &#8377;{products.product.price.toFixed(2)}
                    </span>
                  )}
                </p>
              )}
              {/* <p className=" font-TenorSans border-gray-600 "><span className='font-extrabold'>Material:</span> Satin</p>
              <p className=" font-TenorSans border-gray-600 "><span className='font-extrabold'>Fabric:</span> Jersey</p>
            
              <p className=" font-TenorSans border-gray-600 "><span className='font-extrabold'>Additional Info:</span> Abaya comes with hijab</p> */}

              <p className="mb-1 mt-2 font-TenorSans   border-gray-600 ">
                {/* <span className='font-extrabold'>Description:</span> */}
                <span
                  dangerouslySetInnerHTML={{ __html: products.product.description }}
                />
              </p>
              {products.product.additionalInfo && (
                <p className="border-b mb-5 pb-5 font-TenorSans border-gray-600 "><span className='font-extrabold'>Additional Info:</span> {products.product.additionalInfo}</p>
              )}
              <div className="mb-4 flex gap-4">
                <div className="block">Colours:</div>
                <div className=" border-black flex flex-wrap gap-3">
                  {products.colors.map((color, index) => (
                    <button
                      key={index}
                      className={`rounded-full w-5 h-5 ${selectedColor === color ? 'ring-2 ring-black border border-white' : ''}`}
                      style={{ backgroundColor: getColorValue(color) }}
                      onClick={() => handleColorSelect(color)}
                    >
                      {/* Optional: Add text or icon here */}
                    </button>
                  ))}
                </div>
              </div>

              <div className="mb-4">
                {products.product.size && products.product.size?.length > 0 && (
                  <div className='flex gap-4'>
                    <div className="block mb-2">Size:</div>
                    <div id='size' className="flex space-x-2 flex-wrap gap-2">
                      {products.product.size.map((size, index) => (
                        <button
                          key={index}
                          className={` border-black px-3 h-8 rounded font-TenorSans border ${selectedSize === size ? 'bg-[#754F23] text-white border border-none' : 'bg-transparent text-black'}`}
                          onClick={() => setSelectedSize(size)}
                        >
                          {size}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className='space-x-2 lg:flex'>
                <div className="flex items-center">
                  <button onClick={() => handleQuantityChange(-1)} className="px-3 py-2 border text-md text-black border-[#00000080] font-bold rounded-l">&#8722;</button>
                  <input
                    min="1"
                    type="number"
                    id="quantity"
                    name="quantity"
                    value={quantity}
                    disabled
                    onChange={(e) => setQuantity(Number(e.target.value))}
                    className="border-y spinner outline-none border-[#00000080] bg-transparent p-2 w-20 text-center"
                  />
                  <button onClick={() => handleQuantityChange(1)} className="px-3 py-[9px] bg-[#754F23] text-white text-6 border-[#00000080] font-bold rounded-r">&#43;</button>
                </div>
                <div className='space-x-2 mt-5 lg:mt-0 flex'>
                  <button
                    onClick={handleAddToCart}
                    className={`py-2 px-4 rounded ${products.product.inStock ? 'bg-[#754F23] text-white' : 'bg-red-500 text-white cursor-not-allowed opacity-50'}`}
                    disabled={!products.product.inStock || isAddingToCart}
                  >
                    {products.product.inStock ? (isAddingToCart ? 'Adding...' : 'Add to Cart') : 'Out of Stock'}
                  </button>

                  {wishlistLoading ? (
                    <button onClick={handleWishlistToggle} className={`border text-lg border-[#00000080] rounded px-2 text-black`}>
                      <IoMdHeartEmpty className="text-3xl" />
                    </button>
                  ) : (
                    <button onClick={handleWishlistToggle} className={`border text-lg border-[#00000080] rounded px-2 ${isInWishlist ? 'text-red-500 bg-[#754F23] border-white' : 'text-black'}`}>
                      {isInWishlist ? <IoMdHeart className="text-3xl " /> : <IoMdHeartEmpty className="text-3xl" />}
                    </button>
                  )}
                </div>
                <Modals isOpen={open} closeModal={handleClose} handleClose={handleClose} contentLabel="Login Modal">
                  {isLogin ? (
                    <Login switchToRegister={switchToRegister} />
                  ) : (
                    <Register switchToLogin={switchToLogin} />
                  )}
                </Modals>
              </div>

              {/* Banner offer Ad Code Here  Do Not Remove This */}

              {/* <div className='border mt-5 py-[24px] border-black rounded'>
                <div className='flex border-b border-black gap-2 px-[16px] pb-[16px]'>
                  <TbTruckDelivery className='w-10 h-10' />
                  <div>
                    <h1 className='text-black text-base font-normal font-TenorSans leading-normal'>Free Delivery</h1>
                    <p className='text-black text-xs font-normal underline leading-[18px]'>Enter your postal code for Delivery Availability</p>
                  </div>
                </div>
                <div className='flex gap-2 px-[16px] pt-[16px]'>
                  <HiArrowPath className='w-10 h-10' />
                  <div>
                    <h1 className='text-black text-base font-normal font-TenorSans leading-normal'>Return Delivery</h1>
                    <p className='text-black text-xs font-normal leading-[18px]'>Free 30 Days Delivery Returns. <span className='underline'>Details</span></p>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
        {/* Review and description  */}
        <div className='px-2'>
          <ProductDetailsAndReviews productId={products.product.id} />
        </div>
        <div className='mt-5 sm:mt-0'>
          <SimilarProductsPage categoryId={products.product.categoryId} subCategoryId={products.product.subCategoryId} />
        </div>
      </div>

    </>
  );
};

export default ProductDetails;




const colorMap = {
  "Gray": "#808080",
  "Black": "#000000",
  "Light Green": "#90EE90",
  "Dark Green": "#006400",
  "Dark Blue": "#00008B",
  "Brown": "#A52A2A",
  "Off-White": "#FAF9F6",
  "Dark Chestnut": "#986960",
  "Sea Green": "#2E8B57",
  "Green": "#008000",
  "Shadow": "#8A795D",
  "Royal Blue": "#4169E1",
  "Khaki": "#F0E68C",
  "Satin": "#E1C699",
  "Antique Fuchsia": "#915C83",
  "Puce Red": "#722F37",
  "Gray (X11)": "#BEBEBE",
  "Weldon Blue": "#7C98AB",
  "Shadow Blue": "#778BA5",
  "Golden": "#FFD700",
  "Dark Brown-Tangelo": "#6E4C1E",
  "Black Chocolate": "#1B1811",
  "Deep Lilac": "#9955BB",
  "Dark Chocolate (Hershey's)": "#3C1321",
  "Wintergreen Dream": "#56887D",
  "Lavender": "#E6E6FA",
  "Light Gray": "#D3D3D3",
  "Granite Gray": "#676767",
  "Ash Gray": "#B2BEB5",
  "Deep Puce": "#A95C68",
  "Café Au Lait": "#A67B5B",
  "Jet Stream": "#BFD1E5",
  "Pink": "#FFC0CB",
  "Davy's Grey": "#555555",
  "New York Pink": "#D7837F",
  "Metallic Pink": "#F6A5C0",
  "Sky Magenta": "#CF71AF",
  "Pineapple": "#563C5C",
  "Kombu Green": "#354230",
  "Russian Violet": "#32127A",
  "Cal Poly Pomona Green": "#1E4D2B",
  "Fern Green": "#4F7942",
  "Dark Scarlet": "#560319",
  "Opal": "#A8C3BC",
  "Unbleached Silk": "#FFDDCA",
  "Beau Blue": "#BCD4E6",
  "Cadet Blue (Crayola)": "#A9B2C3",
  "Puce": "#CC8899",
  "Cinnamon Satin": "#CD607E",
  "Glossy Grape": "#AB92B3",
  "Quick Silver": "#A6A6A6",
  "Rhythm": "#777696",
  "Rifle Green": "#444C38",
  "Dark Vanilla": "#D1BEA8",
  "Space Cadet": "#1D2951",
  "Dark Lava": "#483C32",
  "Dark Chocolate": "#3B2F2F",
  "Copper Rose": "#996666",
  "Arsenic": "#3B444B",
  "Deep Taupe": "#7E5E60",
  "Coffee": "#6F4E37",
  "Pewter Blue": "#8BA8B7",
  "White": "#FFFFFF",
  "Dim Gray": "#696969",
};

const getColorValue = (colorName) => {
  return colorMap[colorName] || "#FFFFFF";
};
