import React, { useEffect } from 'react'
import { fetchOffersByProductId, updateOffersByProductId } from '../../../redux/slices/adminSlice';
import { motion } from "framer-motion";

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

const OfferListModal = ({ isOpen, onClose, id }) => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.adminData.offerDetails);

    useEffect(() => {
        if (id) {
            dispatch(fetchOffersByProductId(id));
        }
    }, [dispatch, id]);

    const handleToggleStatus = async (offer) => {
        const updatedStatus = !offer.isActive;

        try {
            await dispatch(updateOffersByProductId({ id: offer.id, status: updatedStatus })).unwrap();
            toast.success(`Offer ${offer.offerName} is now ${updatedStatus ? 'Active' : 'Inactive'}`);

            dispatch(fetchOffersByProductId(id));
        } catch (error) {
            toast.error(`Failed to update offer status: ${error.message}`);
        }
    };

    // const handleSubmit = async () => {

        //     try {
        //         await toast.promise(
        //             updateProduct(product.id, updatedProduct),
        //             {
        //                 loading: 'Updating Product...',
        //                 success: (response) => `${response.message}`,
        //                 error: (err) => `${err.response.data.message}`,
        //             }
        //         )
        //         dispatch(fetchOffersByProductId(id));
        //         onClose();
        //     } catch (error) {
        //         console.log(error)
        //     }
    // };

    if (!isOpen) return null;

    return (
        <div>
            <motion.div>
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-5xl">
                        <h2 className="text-2xl font-semibold mb-4">Product Offer List</h2>
                        <table className="min-w-full divide-y divide-gray-700">
                            <thead>
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Name</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Type</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Start Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">End Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Max Uses</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Current Uses</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Active</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-300">
                                {loading ? (
                                    <tr>
                                        <td colSpan="7" className="px-6 py-4 text-center text-sm text-gray-500">Loading...</td>
                                    </tr>
                                ) : data.length === 0 ? (
                                    <tr>
                                        <td colSpan="7" className="px-6 py-4 text-center text-sm text-gray-500">Empty offer list</td>
                                    </tr>
                                ) : (
                                    data.map((offer) => (
                                        <motion.tr key={offer.id} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]">{offer.offerName}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]">
                                                {offer.offerType === 'percentOff' ? `${offer.percentOff}% Off` : `$${offer.offerPrice}`}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]">{new Date(offer.startDate).toLocaleDateString()}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]">{new Date(offer.endDate).toLocaleDateString()}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]">{offer.isLimited ? offer.maxUses : 'Unlimited'}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]">{offer.currentUses}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]">
                                                <label className="inline-flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        checked={offer.isActive}
                                                        onChange={() => handleToggleStatus(offer)}
                                                        className="form-checkbox h-5 w-5 text-[#754F23] accent-[#754F23]"
                                                    />
                                                </label>
                                            </td>
                                        </motion.tr>
                                    ))
                                )}
                            </tbody>
                        </table>


                        <div className="mt-6 flex justify-end">
                            {/* <button
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                                onClick={onClose}
                            >
                                Cancel
                            </button> */}
                            <button
                                className="px-4 py-3 bg-[#754F23] hover:opacity-80 text-white rounded justify-start items-start gap-4 inline-flex text-sm font-semibold"
                                onClick={onClose}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            </motion.div>
            <div className="mt-6 flex justify-end">
                <button
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                    onClick={onClose}
                >
                    Cancel
                </button>
                <button
                    className="px-4 py-3 bg-[#754F23] hover:opacity-80 text-white rounded justify-start items-start gap-4 inline-flex text-sm font-semibold"
                // onClick={handleSubmit}
                >
                    Save Product
                </button>
            </div>
        </div>
    )
}

export default OfferListModal
