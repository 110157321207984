import axios from 'axios';
import serverUrl from '../config/serverUrl';

const baseUrl = serverUrl;
const adminToken = localStorage.getItem('adminToken');


const getFileExtension = (file) => {
    return file.name.split('.').pop().toLowerCase();
};

export async function uploadImagesToS3(images, productId) {
    try {
        const uploadRequest = images.map((image, index) => (
            {
                extension: getFileExtension(image),
                size: image.size,
                order: index + 1,
            }
        ));


        const uploadUrlsResponse = await axios.post(`${baseUrl}/file`, uploadRequest, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        const { data: uploadUrls } = uploadUrlsResponse.data;

        const uploadPromises = uploadUrls.map(async (urlData, index) => {
            const { url, key } = urlData;
            const formData = new FormData();
            formData.append('file', images[index]);
            await axios.put(url, images[index], {
                headers: { 'Content-Type': images[index].type },
            });

            return {
                extension: urlData.extension,
                key: key,
                order: urlData.order,
            };
        });

        const uploadedImagesMetadata = await Promise.all(uploadPromises);

        await axios.post(`${baseUrl}/admin/products/${productId}/images`, uploadedImagesMetadata, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });

        console.log('Images successfully uploaded and metadata saved!');
    } catch (error) {
        console.error('Error uploading images:', error);
    }
}

