import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Search, Edit } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders } from "../../../redux/slices/adminSlice";
import OrderModal from "./OrderModal";

const formatDate = (date) => {
	const d = new Date(date);
	const year = d.getFullYear();
	const month = String(d.getMonth() + 1).padStart(2, '0');
	const day = String(d.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
};

const OrdersTable = () => {
	const orders = useSelector((state) => state.adminData.orders.data);
	const loading = useSelector((state) => state.adminData.orders.loading);

	const [searchTerm, setSearchTerm] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [page, setPage] = useState(0);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [filteredOrders, setFilteredOrders] = useState([]);
	const dispatch = useDispatch();


	useEffect(() => {
		if (searchTerm === "") {
			setFilteredOrders(orders);
		} else {
			setFilteredOrders(
				orders.filter(
					(order) =>
						order?.id?.toLowerCase().includes(searchTerm) ||
						order?.shippingAddress?.firstName?.toLowerCase().includes(searchTerm)
				)
			);
		}
	}, [orders, searchTerm]);

	useEffect(() => {
		dispatch(fetchOrders(page));
	}, [dispatch, page]);

	const handleSearch = (e) => {
		setSearchTerm(e.target.value.toLowerCase());
	};

	const handleNext = async () => {
		try {
			setPage((prevPage) => prevPage + 1);
			// await dispatch(fetchOrders(page + 1));
		} catch (error) {
			console.error("Error loading more products:", error);
		}
	};

	const handlePrev = async () => {
		try {
			if (page > 0) {
				setPage((prevPage) => prevPage - 1);
				// await dispatch(fetchOrders(page - 1));
			}
		} catch (error) {
			console.error("Error loading more products:", error);
		}
	};


	const openModal = (id) => {
		setSelectedOrder(id);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedOrder(null);
	};

	return (
		<>
			<OrderModal isOpen={isModalOpen} page={page} onClose={closeModal} id={selectedOrder} />
			<motion.div
				className='bg-white backdrop-blur-md font-Inter shadow-lg mb-8 rounded-xl p-6'
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ delay: 0.4 }}
			>
				{loading && (
					<div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10 rounded-xl">
						<p className="text-gray-700">Loading...</p>
					</div>
				)}
				<div className='flex justify-between items-center mb-6'>
					<h2 className='text-xl font-medium text-black'>Orders</h2>
					<div className='relative'>
						<input
							type='text'
							placeholder='Search orders...'
							className='bg-gray-100 border text-black placeholder-gray-400 rounded-lg pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#DB4444]'
							value={searchTerm}
							onChange={handleSearch}
						/>
						<Search className='absolute left-3 top-2.5 text-gray-400' size={18} />
					</div>
				</div>

				<div className='overflow-x-auto h-[500px] no-scrollbar'>
					<table className='min-w-full divide-y divide-gray-700'>
						<thead className="sticky">
							<tr>
								<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>
									Order ID
								</th>
								<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>
									Total
								</th>
								<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>
									Status
								</th>
								<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>
									Date
								</th>
								<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>
									Actions
								</th>
							</tr>
						</thead>
						<tbody className='divide divide-y divide-gray-300'>
							{filteredOrders.map((order) => (
								<motion.tr
									key={order?.id}
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ duration: 0.3 }}
								>
									<td className='px-6 py-4 whitespace-nowrap text-sm font-normal text-[#555F7E]'>
										{order?.id?.toUpperCase()}
									</td>
									<td className='px-6 py-4 whitespace-nowrap text-sm font-normal text-[#555F7E]'>
										₹{order?.finalCartvalue?.toFixed(2)}
									</td>
									<td className='px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]'>
										<span
											className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${order?.order_statuses[0]?.status === "delivered"
												? "bg-green-100 text-green-800"
												: order?.order_statuses[0]?.status === "processing"
													? "bg-yellow-100 text-yellow-800"
													: order?.order_statuses[0]?.status === "shipped"
														? "bg-blue-100 text-blue-800"
														: order?.order_statuses[0]?.status === "received"
															? "bg-black/50 text-white"
															: "bg-red-100 text-red-800"
												}`}
										>
											{order?.order_statuses[0]?.status}
										</span>
									</td>
									<td className='px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]'>
										{formatDate(order?.createdAt)}
									</td>
									<td className='px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]'>
										<button onClick={() => openModal(order.id)} className='text-indigo-400 hover:text-indigo-300 mr-2'>
											<Edit size={18} />
										</button>
									</td>
								</motion.tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="gap-2 flex justify-end pt-2 space-x-2">
					<button
						className='bg-red-950 text-white rounded px-2 hover:bg-red-500'
						onClick={handlePrev}
						disabled={page === 0}
					>
						Prev
					</button>
					<button className='bg-red-950 text-white rounded px-2 hover:bg-red-500' onClick={handleNext}>
						Next
					</button>
				</div>
			</motion.div>
		</>
	);
};

export default OrdersTable;
