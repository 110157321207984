import React from 'react';

const Tabs = ({ activeTab, onTabClick }) => {
    return (
        <div
            className="flex font-TenorSans justify-center mt-5 w-full mb-6"
        >
            <button
                whileTap={{ scale: 0.95 }}
                className={`px-4 w-full py-2 border-b-2 border-[#754F23] transition-colors duration-300 ${activeTab === "details" ? "bg-[#754F23] text-white" : "border-gray-200"
                    }`}
                onClick={() => onTabClick("details")}
            >
                Product Details
            </button>
            <button
                whileTap={{ scale: 0.95 }}
                className={`px-4 w-full py-2 border-b-2 border-[#754F23] transition-colors duration-300 ${activeTab === "reviews" ? "bg-[#754F23] text-white" : "border-gray-200"
                    }`}
                onClick={() => onTabClick("reviews")}
            >
                Reviews
            </button>
        </div>
    );
};

export default Tabs;
