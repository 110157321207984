import React from 'react';
import Breadcrumbs from '../../shared/Breadcrumbs';

const Policy = () => {
    return (
        <div className="max-w-6xl mb-[100px] px-2 sm:px-0 mx-auto py-8">
            <div className=" sm:my-[20px]">
                <Breadcrumbs />
            </div>
            <h1 className="text-4xl font-bold mb-6 ">Privacy Policy</h1>

            <h2 className="text-2xl font-semibold mt-4 mb-2 ">1. Introduction</h2>
            <p className=" mb-6">
                At <strong>Humaira Abayas</strong>, we respect your privacy and are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, and safeguard your data.            </p>

            <h2 className="text-2xl font-semibold mt-4 mb-2 ">2. Information We Collect</h2>
            <p className=" mb-4">
                <strong>Personal Information</strong>: When you create an account, make a purchase, or contact us, we collect personal information such as your name, email address, phone number, shipping address, and payment details.            </p>
            <p className=" mb-4">
                <strong>Non-Personal Information</strong>: We may also collect non-personal data such as browser type, IP address, and browsing behavior for analytical purposes.
            </p>

            <h2 className="text-2xl font-semibold mt-4 mb-2 text--00">3. How We Use Your Information</h2>
            <div>
                <p>We may use your information for the following purposes:</p>
                <ul className='space-y-2'>
                    <li>● To process and fulfill orders</li>
                    <li>● To communicate with you about your orders, updates, and promotions</li>
                    <li>● To improve our website and services</li>
                    <li>● To provide customer support</li>
                    <li>● To send marketing communications, if you have opted in</li>
                </ul>
            </div>

            <h2 className="text-2xl font-semibold mt-4 mb-2 text--500">4. Sharing Your Information</h2>
            <p className=" mb-4">
                We will never sell or rent your personal information to third parties. However, we may share your information with:
                <ul className=' space-y-2'>
                    <li>● <strong>Service Providers</strong>: We may share your data with trusted third-party service providers who help us process orders, payments, and shipping.</li>
                    <li>● <strong>Legal Compliance</strong>: We may disclose your information if required by law, such as in response to a court order or government request.
                    </li>
                </ul>
            </p>

            <h2 className="text-2xl font-semibold mt-4 mb-2 text--500">5. Data Security</h2>
            <p className=" mb-4">
                We implement a variety of security measures to protect your personal data. These measures include encryption, secure servers, and regular security updates. However, no data transmission over the internet can be guaranteed 100% secure.            </p>

            <h2 className="text-2xl font-semibold mt-4 mb-2 text--500">6. Cookies</h2>
            <p className=" mb-4">
                We use cookies to enhance your experience on our website. Cookies are small files stored on your device that allow us to recognize your preferences and track website usage.
            </p>

            <h2 className="text-2xl font-semibold mt-4 mb-2 text--500">7. Your Rights</h2>
            <p className=" mb-4">
                You have the right to:
                <ul className='space-y-2'>
                    <li>● Access the personal data we hold about you</li>
                    <li>● Request corrections to your personal data</li>
                    <li>● Request the deletion of your data, subject to certain conditions</li>
                    <li>● Opt out of marketing communications at any time</li>
                </ul>
            </p>

            <h2 className="text-2xl font-semibold mt-4 mb-2 text--500">8. Children’s Privacy</h2>
            <p className=" mb-4">
                Humaira Abayas does not knowingly collect personal information from children under the age of 13. If we become aware that we have collected such information, we will take steps to delete it.
            </p>

            <h2 className="text-2xl font-semibold mt-4 mb-2 text--500">9. Changes to the Privacy Policy</h2>
            <p className=" mb-4">
                We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the updated policy will be effective from the date of posting.
            </p>
            <h1 className="text-3xl font-bold mb-6 mt-8 text--500">Shipping Policy</h1>
            <p className=" mb-4">
                We aim to process and ship all orders within 1-2 business days. Once your order has been shipped, delivery typically takes between 3-5 business days, depending on your location.
            </p>
            <p className=" mb-4">
                You can expect your order to arrive within 5-7 business days from the date of purchase.
            </p>
            <p className=" mb-4">
                Please note, shipping times may vary during peak seasons or due to unforeseen circumstances. We will notify you of any delays.            </p>
            <h2 className="text-2xl font-semibold mt-4 mb-2 text--500">10. Contact Us</h2>
            <p className=" mb-4">
                If you have any questions or concerns about our Privacy Policy, please contact us at:
            </p>
            <p className=" mb-4">
                Email: official.humairaabayas@gmail.com<br />
                Phone: +91 8639310409
            </p>
        </div>
    );
}

export default Policy;
