import React, { useState } from 'react';
import { motion } from "framer-motion";
import { FaCloudUploadAlt, FaTimes } from "react-icons/fa";
import { uploadImagesToS3 } from '../../../services/imageUploadService'
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { fetchAllProducts } from '../../../redux/slices/productSlice';

const ImageUploadModal = ({ isOpen, page, onClose, product }) => {
    const dispatch = useDispatch()
    const [imageFiles, setImageFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        if (imageFiles.length + files.length > 6) {
            alert("You can only upload up to 6 images.");
            return;
        }
        const newFiles = [...imageFiles, ...files];
        setImageFiles(newFiles);
        const newPreviews = newFiles.map((file) => URL.createObjectURL(file));
        setImagePreviews(newPreviews);
    };

    const handleRemoveImage = (index) => {
        const newFiles = [...imageFiles];
        newFiles.splice(index, 1);
        setImageFiles(newFiles);

        const newPreviews = [...imagePreviews];
        newPreviews.splice(index, 1);
        setImagePreviews(newPreviews);
    };

    const handleSubmit = async () => {
        const productId = product.id
        try {
            await toast.promise(
                uploadImagesToS3(imageFiles, productId),
                {
                    loading: 'Uploading Image...',
                    success: `Images successfully uploaded!`,
                    error: (err) => `${err.message}`,
                }
            )
            setImageFiles([]);
        } catch (error) {
            console.log(error)
        }
        dispatch(fetchAllProducts({ page }));
        onClose();
    };

    if (!isOpen) return null;

    return (
        <motion.div>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
                    <h2 className="text-2xl font-semibold mb-4">Upload Product Images</h2>

                    {/* Image Upload Section */}
                    <div className="flex flex-col items-center justify-center">
                        <label className="w-full h-32 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg cursor-pointer">
                            <FaCloudUploadAlt size={40} className="text-gray-400" />
                            <span className="text-gray-400 mt-2">Click to upload images</span>
                            <input
                                type="file"
                                accept="image/*"
                                multiple
                                onChange={handleFileChange}
                                className="hidden"
                            />
                        </label>
                    </div>

                    {/* Image Preview List */}
                    {imagePreviews.length > 0 && (
                        <div className="mt-4 grid grid-cols-3 gap-4">
                            {imagePreviews.map((preview, index) => (
                                <div key={index} className="relative">
                                    <img
                                        src={preview}
                                        alt={`Preview ${index}`}
                                        className="w-full h-32 object-cover rounded-lg"
                                    />
                                    <button
                                        className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1"
                                        onClick={() => handleRemoveImage(index)}
                                    >
                                        <FaTimes size={16} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Submit and Cancel Buttons */}
                    <div className="mt-6 flex justify-end">
                        <button
                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-4 py-3 bg-[#754F23] hover:opacity-80 text-white rounded text-sm font-semibold"
                            onClick={handleSubmit}
                        >
                            Upload Images
                        </button>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default ImageUploadModal;
