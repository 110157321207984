import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

const Accordion = ({ title, isOpen, onToggle, children }) => (
    <div className="relative">
        <button
            onClick={onToggle}
            className="flex items-center w-full gap-1 text-left py-1 hover:text-gray-700"
        >
            {title}
        </button>
        {isOpen && (
            <div className="ml-4 mt-2">
                {children}
            </div>
        )}
    </div>
);

const abayaData = [
    { name: 'Nida Fabric', catId: 'abayas', subCatId: 'nida-fabric' },
    { name: 'TikTok Fabric', catId: 'abayas', subCatId: 'tiktok-fabric' },
    { name: 'Harira Fabric', catId: 'abayas', subCatId: 'harira-fabric' },
    { name: 'CEY Fabric', catId: 'abayas', subCatId: 'cey-fabric' },
    { name: 'Linen Fabric', catId: 'abayas', subCatId: 'linen-fabric' },
    { name: 'Korean Nida Fabric', catId: 'abayas', subCatId: 'korean-nida-fabric' },
    { name: 'Self printed Nida Fabric', catId: 'abayas', subCatId: 'self-printed-nida-fabric' },

];

const abayaGroups = [
    abayaData.slice(0, 3),
    abayaData.slice(3, 6),
    abayaData.slice(6),
];

const hijabData = [
    { name: 'Cotton Luxury', catId: 'hijabs', subCatId: 'cotton-luxury' },
    { name: 'Premium Satin', catId: 'hijabs', subCatId: 'premium-satin' },
    { name: 'Georgette', catId: 'hijabs', subCatId: 'georgette' },
    { name: 'Georgette Chiffon', catId: 'hijabs', subCatId: 'georgette-chiffon' },
    { name: 'Georgette Pearl', catId: 'hijabs', subCatId: 'georgette-pearl' },
];


const hijabGroups = [
    hijabData.slice(0, 3),
    hijabData.slice(3, 6),
    hijabData.slice(6)
];


const accessoriesData = [
    { name: 'Hijab Tube Inner Cap', catId: 'accessories', subCatId: 'hijab-tube-inner-cap' },
    { name: 'Jersey Tie Undercap', catId: 'accessories', subCatId: 'jersey-tie-undercap' },
    { name: 'Hijab Magnets', catId: 'accessories', subCatId: 'hijab-magnets' },
    { name: 'Mini Straight Basic Pins', catId: 'accessories', subCatId: 'mini-straight-basic-pins' },
    // { name: 'Accessories2', catId: 'abayas-001', subCatId: 'silk-shiny-fabric' },
    // { name: 'Accessories2.1', catId: 'abayas-001', subCatId: 'zoom-fabric' },
    // { name: 'Accessories2.2', catId: 'abayas-001', subCatId: 'korean-nida-fabric' },
    // { name: 'Accessories2.3', catId: 'abayas-001', subCatId: 'korean-nida-fabric2' },
    // { name: 'Accessories3', catId: 'abayas-001', subCatId: 'korean-nida-fabric3' },
    // { name: 'Accessories3.1', catId: 'abayas-001', subCatId: 'korean-nida-fabric4' },
    // { name: 'Accessories3.2', catId: 'abayas-001', subCatId: 'korean-nida-fabric5' },
    // { name: 'Accessories3.3', catId: 'abayas-001', subCatId: 'korean-nida-fabric6' },
];


const accessoriesGroups = [
    accessoriesData.slice(0, 2),
    accessoriesData.slice(2, 4),
    accessoriesData.slice(4, 8)
];


const Navigation = () => {
    const [showHijabDropdown, setShowHijabDropdown ] = useState(false);
    const [showAbayasDropdown, setShowAbayasDropdown] = useState(false);
    const [showAccessoriesDropdown, setShowAccessoriesDropdown ] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openAccordion, setOpenAccordion] = useState(null);

    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev);
    };

    const handleAccordionToggle = (section) => {
        setOpenAccordion(prev => prev === section ? null : section);
    };

    const handleMouseEnter = (setDropdown) => {
        setDropdown(true);
    };

    const handleMouseLeave = (setDropdown) => {
        setDropdown(false);
    };

    return (
        <nav className='font-PlayfairDisplay text-lg flex space-x-6'>

            {/* Hamburger menu for screens below md */}
            <div className="lg:hidden relative">
                <button onClick={toggleMenu} className="sm:text-2xl focus:outline-none">
                    <FiMenu />
                </button>

                {/* Left-Side Navigation */}
                <div
                    className={`fixed top-0 left-0 h-full w-72 bg-white shadow-lg transition-transform duration-300 ease-in-out z-50 ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'
                        }`}
                >
                    <div className="p-5">
                        <button onClick={toggleMenu} className="sm:text-2xl float-end mb-4 focus:outline-none">
                            <AiOutlineClose />
                        </button>
                        <div className="flex mt-5 flex-col space-y-2">
                            <Link to="/" onClick={toggleMenu} className="hover:text-gray-700">Home</Link>
                            <Link to="/hijabs" onClick={toggleMenu} className="hover:text-gray-700">Hijab</Link>
                            <Accordion
                                title="Abayas"
                                isOpen={openAccordion === 'abayas'}
                                onToggle={() => handleAccordionToggle('abayas')}
                            >
                                {
                                    abayaData.map((item) =>
                                        <Link to={`/${item.catId}/${item.subCatId}`} key={item.subCatId} onClick={toggleMenu} className="block text-[#2F2F2F] mt-2">{item.name}</Link>
                                    )
                                }
                            </Accordion>

                            <Link to="/accessories" onClick={toggleMenu} className="hover:text-gray-700">Accessories</Link>
                            <Link to="/contact" onClick={toggleMenu} className="hover:text-gray-700">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>





            {/* Main navigation for larger screens */}
            <div className='hidden lg:flex space-x-6'>
                <Link to="/" className="hover:text-gray-700">Home</Link>
                <div
                    className="relative"
                    onMouseEnter={() => handleMouseEnter(setShowHijabDropdown)}
                    onMouseLeave={() => handleMouseLeave(setShowHijabDropdown)}
                >
                    <Link
                        to="/hijabs"
                        className="hover:text-gray-700 flex items-center gap-1"
                    >
                        Hijabs
                    </Link>
                    <div
                        className={`absolute w-[400px] left-1/2 mt-2 -translate-x-1/2 max-w-[502px] grid grid-cols-2 gap-4 rounded-[10px] bg-white/40 backdrop-blur-[10.80px] shadow-lg p-4 transition-all duration-300 ease-in-out ${showHijabDropdown ? 'opacity-100 translate-y-0 visible' : 'opacity-0 translate-y-4 invisible'
                            }`}
                    >
                        <div className="flex flex-col items-center gap-y-2">
                            {
                                hijabGroups[0].map((item) =>
                                    <Link to={`/${item.catId}/${item.subCatId}`} key={item.subCatId}  className="block text-[#2F2F2F] ">{item.name}</Link>
                                )
                            }
                        </div>

                        {/* uncomment this code when want to increase line */}

                        <div className={`flex flex-col items-center gap-y-2`}>
                            {
                                hijabGroups[1]?.length !== 0 && hijabGroups[1].map((item) =>
                            <Link to={`/${item.catId}/${item.subCatId}`} key={item.subCatId} className="block text-[#2F2F2F] ">{item.name}</Link>
                                )
                            }
                        </div>
                        <div className={`flex flex-col items-center gap-y-2 ${hijabGroups.length === 2 ? '' : 'hidden'} `}>
                            {
                            hijabGroups[2]?.length !== 0 && hijabGroups[2].map((item) =>
                            <Link to={`/${item.catId}/${item.subCatId}`} key={item.subCatId} className="block text-[#2F2F2F] ">{item.name}</Link>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div
                    className="relative"
                    onMouseEnter={() => handleMouseEnter(setShowAbayasDropdown)}
                    onMouseLeave={() => handleMouseLeave(setShowAbayasDropdown)}
                >
                    <Link
                        to="/abayas"
                        className="hover:text-gray-700 flex items-center gap-1"
                    >
                        Abayas
                    </Link>
                    <div
                        className={`absolute max-w-[650px] text-start left-1/2 mt-2 -translate-x-1/2 w-[630px] grid grid-cols-3  rounded-[10px] bg-white/40 backdrop-blur-[10.80px] shadow-lg p-4 transition-all duration-300 ease-in-out ${showAbayasDropdown ? 'opacity-100 translate-y-0 visible' : 'opacity-0 translate-y-4 invisible'
                            }`}
                    >
                        <div className="flex flex-col gap-y-2 ">
                            {
                                abayaGroups[0].map((item) =>
                                    <Link to={`/${item.catId}/${item.subCatId}`} key={item.subCatId} className="block text-[#2F2F2F] ">{item.name}</Link>
                                )
                            }

                        </div>
                        <div className="flex w-full flex-col gap-y-2 ">
                            {
                                abayaGroups[1].map((item) =>
                                    <Link to={`/${item.catId}/${item.subCatId}`} key={item.subCatId} className="block text-[#2F2F2F] ">{item.name}</Link>
                                )
                            }
                        </div>
                        <div className="flex w-full flex-col gap-y-2 ">
                            {
                                abayaGroups[2].map((item) =>
                                    <Link to={`/${item.catId}/${item.subCatId}`} key={item.subCatId} className="block text-[#2F2F2F] ">{item.name}</Link>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div
                    className="relative"
                    onMouseEnter={() => handleMouseEnter(setShowAccessoriesDropdown)}
                    onMouseLeave={() => handleMouseLeave(setShowAccessoriesDropdown)}
                >
                    <Link
                        to="/accessories"
                        className="hover:text-gray-700 flex items-center gap-1"
                    >
                        Accessories
                    </Link>
                    <div
                        className={`absolute w-[502px] left-1/2 mt-2 max-w-[502px] -translate-x-1/4 grid grid-cols-2 gap-4 rounded-[10px] bg-white/40 backdrop-blur-[10.80px] shadow-lg p-4 transition-all duration-300 ease-in-out ${showAccessoriesDropdown ? 'opacity-100 translate-y-0 visible' : 'opacity-0 translate-y-4 invisible'
                            }`}
                    >
                        <div className="flex flex-col items-center">
                            <div className="flex flex-col mt-2 space-y-1 text-[#2F2F2F]">

                                {
                                    accessoriesGroups[0].map((item) =>
                                        <Link to={`/${item.catId}/${item.subCatId}`} key={item.subCatId} className="block">{item.name}</Link>
                                    )
                                }
                            </div>
                        </div>
                        {/* Accessories Item 2 */}
                        <div className="flex flex-col  items-center">
                            <div className="flex flex-col mt-2 space-y-1 text-[#2F2F2F]">
                                {
                                    accessoriesGroups[1].map((item) =>
                                        <Link to={`/${item.catId}/${item.subCatId}`} key={item.subCatId} className="block">{item.name}</Link>
                                    )
                                }
                            </div>
                        </div>
                        {/* Accessories Item 3 */}
                        {/* <div className="flex flex-col items-center">
                            <div className="flex flex-col mt-2 space-y-1 text-[#2F2F2F]">
                                {
                                    accessoriesGroups[2].map((item) =>
                                        <Link to={`/${item.catId}/${item.subCatId}`} key={item.subCatId} className="block">{item.name}</Link>
                                    )
                                }
                            </div>
                        </div> */}
                    </div>
                </div>
                <Link to="/contact" className="hover:text-gray-700 lg:hidden xl:block">Contact Us</Link>
            </div>
        </nav>
    );
};

export default Navigation;
