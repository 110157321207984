const ColorCircle = ({ color, selected, onSelect }) => {

    const colorValue = getColorValue(color);

    return (
        <div
            className={`h-5 w-5 rounded-full cursor-pointer border-2 ${selected ? 'border-black' : ''}`}
            style={{ backgroundColor: colorValue }}
            onClick={() => onSelect(color)}
        />
    );
};

export default ColorCircle;



const colorMap = {
    "Gray": "#808080",
    "Black": "#000000",
    "Light Green": "#90EE90",
    "Dark Green": "#006400",
    "Dark Blue": "#00008B",
    "Brown": "#A52A2A",
    "Off-White": "#FAF9F6",
    "Dark Chestnut": "#986960",
    "Sea Green": "#2E8B57",
    "Green": "#008000",
    "Shadow": "#8A795D",
    "Royal Blue": "#4169E1",
    "Khaki": "#F0E68C",
    "Satin": "#E1C699",
    "Antique Fuchsia": "#915C83",
    "Puce Red": "#722F37",
    "Gray (X11)": "#BEBEBE",
    "Weldon Blue": "#7C98AB",
    "Shadow Blue": "#778BA5",
    "Golden": "#FFD700",
    "Dark Brown-Tangelo": "#6E4C1E",
    "Black Chocolate": "#1B1811",
    "Deep Lilac": "#9955BB",
    "Dark Chocolate (Hershey's)": "#3C1321",
    "Wintergreen Dream": "#56887D",
    "Lavender": "#E6E6FA",
    "Light Gray": "#D3D3D3",
    "Granite Gray": "#676767",
    "Ash Gray": "#B2BEB5",
    "Deep Puce": "#A95C68",
    "Café Au Lait": "#A67B5B",
    "Jet Stream": "#BFD1E5",
    "Pink": "#FFC0CB",
    "Davy's Grey": "#555555",
    "New York Pink": "#D7837F",
    "Metallic Pink": "#F6A5C0",
    "Sky Magenta": "#CF71AF",
    "Pineapple": "#563C5C",
    "Kombu Green": "#354230",
    "Russian Violet": "#32127A",
    "Cal Poly Pomona Green": "#1E4D2B",
    "Fern Green": "#4F7942",
    "Dark Scarlet": "#560319",
    "Opal": "#A8C3BC",
    "Unbleached Silk": "#FFDDCA",
    "Beau Blue": "#BCD4E6",
    "Cadet Blue (Crayola)": "#A9B2C3",
    "Puce": "#CC8899",
    "Cinnamon Satin": "#CD607E",
    "Glossy Grape": "#AB92B3",
    "Quick Silver": "#A6A6A6",
    "Rhythm": "#777696",
    "Rifle Green": "#444C38",
    "Dark Vanilla": "#D1BEA8",
    "Space Cadet": "#1D2951",
    "Dark Lava": "#483C32",
    "Dark Chocolate": "#3B2F2F",
    "Copper Rose": "#996666",
    "Arsenic": "#3B444B",
    "Deep Taupe": "#7E5E60",
    "Coffee": "#6F4E37",
    "Pewter Blue": "#8BA8B7",
    "White": "#FFFFFF",
    "Dim Gray": "#696969",
};

const getColorValue = (colorName) => {
    return colorMap[colorName] || "#FFFFFF";
};
