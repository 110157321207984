import React from 'react';
import ReviewList from './ReviewList';
import AddReviewForm from './AddReviewForm';
import RatingOverview from './RatingOverview';


const Reviews = ({ productId }) => {
    return (
        <div
        className='bg-white sm:backdrop-blur-md sm:shadow-lg rounded-xl p-2 sm:p-6'
    >
        <div className="reviews-section">
            <h2 className="text-2xl font-bold mb-4">Reviews & Ratings</h2>
            <RatingOverview />
            <ReviewList />
            <AddReviewForm id={productId} />
        </div>
        </div>
    );
};

export default Reviews;
