import React, { useEffect, useState } from 'react';
import { createOffer } from '../../../services/adminProducts';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { getOffers } from '../../../redux/slices/categorySlice';
import { fetchAllProducts } from '../../../redux/slices/productSlice';

const OfferModal = ({ isOpen, page, productId, onClose }) => {
    const dispatch = useDispatch();
    const [offerData, setOfferData] = useState({
        productId: productId,
        offerName: '',
        offerDescription: '',
        offerType: 'percentOff',
        percentOff: 0,
        offerPrice: '',
        startDate: '',
        endDate: '',
        isLimited: false,
        maxUses: ''
    });


    useEffect(() => {
        if (productId) {
            setOfferData((prevData) => ({
                ...prevData,
                productId: productId
            }));
        }
    }, [productId]);

    const handleOfferChange = (e) => {
        const { name, value, type, checked } = e.target;
        setOfferData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox'
                ? checked
                : name === 'percentOff' || name === 'offerPrice' || name === 'maxUses'
                    ? Number(value)
                    : value
        }));
    };
    

    const handleSubmitOffer = async () => {
        try {
            await toast.promise(createOffer(offerData),
                {
                    loading: 'Creating Offer...',
                    success: (response) => `${response.message}`,
                    error: (err) => `${err.message}`,
                });
            dispatch(getOffers());
            dispatch(fetchAllProducts({ page }));
            onClose();
        } catch (error) {
            console.log(error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-1/2">
                <h2 className="text-2xl font-medium mb-4">Create Offer</h2>
                <div className="grid grid-cols-2 gap-4">
                    {/* <div>
                        <label className="block text-[#8e95a9] text-sm font-medium">Product:</label>
                        <select
                            name="productId"
                            value={offerData.productId}
                            onChange={handleOfferChange}
                            className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-1 focus:ring-[#754F23]"
                        >
                            <option value="">Select a product</option>
                            {products?.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {product.productName}, {product.color},
                                </option>
                            ))}
                        </select>
                    </div> */}
                    <div>
                        <label className="block text-[#8e95a9] text-sm font-medium">Offer Name:</label>
                        <input
                            type="text"
                            name="offerName"
                            value={offerData.offerName}
                            onChange={handleOfferChange}
                            className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-1 focus:ring-[#754F23]"
                        />
                    </div>
                    <div>
                        <label className="block text-[#8e95a9] text-sm font-medium">Offer Description:</label>
                        <input
                            type="text"
                            name="offerDescription"
                            value={offerData.offerDescription}
                            onChange={handleOfferChange}
                            className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-1 focus:ring-[#754F23]"
                        />
                    </div>
                    <div>
                        <label className="block text-[#8e95a9] text-sm font-medium">Offer Type:</label>
                        <select
                            name="offerType"
                            value={offerData.offerType}
                            onChange={handleOfferChange}
                            className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-1 focus:ring-[#754F23]"
                        >
                            <option value="percentOff">Percent Off</option>
                            <option value="offerPrice">Offer Price</option>
                        </select>
                    </div>
                    {offerData.offerType === 'percentOff' && (
                        <div>
                            <label className="block text-[#8e95a9] text-sm font-medium">Percent Off:</label>
                            <input
                                type="number"
                                name="percentOff"
                                value={offerData.percentOff}
                                onChange={handleOfferChange}
                                className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-1 focus:ring-[#754F23]"
                            />
                        </div>
                    )}
                    {offerData.offerType === 'offerPrice' && (
                        <div>
                            <label className="block text-[#8e95a9] text-sm font-medium">Offer Price:</label>
                            <input
                                type="number"
                                name="offerPrice"
                                value={offerData.offerPrice}
                                onChange={handleOfferChange}
                                className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-1 focus:ring-[#754F23]"
                            />
                        </div>
                    )}
                    <div>
                        <label className="block text-[#8e95a9] text-sm font-medium">Start Date:</label>
                        <input
                            type="date"
                            name="startDate"
                            value={offerData.startDate}
                            onChange={handleOfferChange}
                            className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-1 focus:ring-[#754F23]"
                        />
                    </div>
                    <div>
                        <label className="block text-[#8e95a9] text-sm font-medium">End Date:</label>
                        <input
                            type="date"
                            name="endDate"
                            value={offerData.endDate}
                            onChange={handleOfferChange}
                            className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-1 focus:ring-[#754F23]"
                        />
                    </div>
                    <div>
                        <label className="block text-[#8e95a9] text-sm font-medium">Is Limited:</label>
                        <div className="flex items-center gap-x-2">
                            <input
                                type="radio"
                                name="isLimited"
                                checked={offerData.isLimited}
                                onChange={() => setOfferData(prevState => ({
                                    ...prevState,
                                    isLimited: true
                                }))}
                                className="form-radio"
                            />
                            <span>Yes</span>
                            <input
                                type="radio"
                                name="isLimited"
                                checked={!offerData.isLimited}
                                onChange={() => setOfferData(prevState => ({
                                    ...prevState,
                                    isLimited: false,
                                    maxUses: ''
                                }))}
                                className="form-radio"
                            />
                            <span>No</span>
                        </div>
                    </div>
                    {offerData.isLimited && (
                        <div>
                            <label className="block text-[#8e95a9] text-sm font-medium">Max Uses:</label>
                            <input
                                type="number"
                                name="maxUses"
                                value={offerData.maxUses}
                                onChange={handleOfferChange}
                                className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-1 focus:ring-[#754F23]"
                            />
                        </div>
                    )}
                </div>
                <div className="flex justify-end mt-4 gap-x-4">
                    <button
                        onClick={onClose}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                    >
                        Close
                    </button>
                    <button
                        onClick={handleSubmitOffer}
                        className="w-full py-2 bg-[#754F23] text-white rounded-lg hover:bg-[#754F23]/90"
                    >
                        Submit Offer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OfferModal;
