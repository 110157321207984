import axios from 'axios';
import serverUrl from '../config/serverUrl';


const API_URL = `${serverUrl}/admin/categories`;
const adminToken = localStorage.getItem('adminToken')


const createCategory = async (data) => {

    try {
        const response = await axios.post(API_URL, data, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        throw error.response.data
    }
};


const addSubCategory = async (subCategoryData) => {
    try {
        const response = await axios.post(`${API_URL}/subcategories`, subCategoryData, {
            headers: { Authorization: `Bearer ${adminToken}` }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        throw error.response.data
    }
};

const getCategories = async () => {
    const response = await axios.get(`${API_URL}`, {
        headers: { Authorization: `Bearer ${adminToken}` }
    });
    return response.data;
};

const getCategoryById = async (id) => {
    const response = await axios.get(`${API_URL}/${id}`, {
        headers: { Authorization: `Bearer ${adminToken}` }
    });
    return response.data;
};

const getSubCategories = async (id) => {
    const response = await axios.get(`${API_URL}/subcategories/${id}`, {
        headers: { Authorization: `Bearer ${adminToken}` }
    });
    return response.data;
};


const getSubCategoryById = async (subCategoryId) => {
    const response = await axios.get(`${API_URL}/subcategory/${subCategoryId}`, {
        headers: { Authorization: `Bearer ${adminToken}` }
    });
    return response.data;
};

const categoryService = {
    createCategory,
    getSubCategories,
    addSubCategory,
    getCategories,
    getCategoryById,
    getSubCategoryById,
};

export default categoryService;