import { motion } from "framer-motion";
import { Edit, Search, List, Image, Gift } from "lucide-react";
import { useEffect, useState } from "react";
// import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
// import { deleteProduct } from "../../../services/adminProducts";
import UpdateProductModal from "./UpdateProductModal";
import { fetchAllProducts } from "../../../redux/slices/productSlice";
import ImageUploadModal from "./ImageUploadModal";
import OfferModal from "../campaign/CreateOfferModal";
import OfferListModal from "./OfferModal";

const ProductsTable = () => {
	const dispatch = useDispatch();
	const categoryState = useSelector((state) => state.products.allProducts);

	const { data, isLoading } = categoryState || {};
	const loading = isLoading;
	const [searchTerm, setSearchTerm] = useState("");

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isImageModalOpen, setIsImageModalOpen] = useState(false);
	const [isOfferModalOpen, setIsOfferModalOpen] = useState(false)
	const [isOfferListModalOpen, setIsOfferListModalOpen] = useState(false)

	const [filteredProducts, setFilteredProducts] = useState([])
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [page, setPage] = useState(0);


	// Filter products based on search term and data changes
	useEffect(() => {
		if (data) {
			setFilteredProducts(
				data.filter((product) =>
					product?.productName?.toLowerCase().includes(searchTerm) ||
					product?.id?.toLowerCase().includes(searchTerm)
				)
			);
		}
	}, [data, searchTerm]);

	useEffect(() => {
		dispatch(fetchAllProducts({ page }));
	}, [dispatch, page]);

	// const handleDelete = async (productId) => {
	// 	try {
	// 		await toast.promise(
	// 			deleteProduct(productId),
	// 			{
	// 				loading: 'Deleting Product...',
	// 				success: (response) => `${response.message}`,
	// 				error: (err) => `${err.message}`,
	// 			}
	// 		);
	// 		const result = await dispatch(fetchAllProducts({ page }));
	// 		if (result) {
	// 			console.log('Products refetched successfully');
	// 		}
	// 	} catch (error) {
	// 		console.error('Delete Error:', error);
	// 	}
	// };


	const handleSearch = (e) => {
		const term = e.target.value.toLowerCase();
		setSearchTerm(term);
	};

	const handleNext = async () => {
		try {
			setPage((prevPage) => prevPage + 1);
			await new Promise((resolve) => setTimeout(resolve, 0));
			// await dispatch(fetchAllProducts({ page }));
		} catch (error) {
			console.error("Error loading more products:", error);
		}
	};

	const handlePrev = async () => {
		try {
			setPage((prevPage) => prevPage - 1);
			await new Promise((resolve) => setTimeout(resolve, 0));
			// await dispatch(fetchAllProducts({ page }));
		} catch (error) {
			console.error("Error loading more products:", error);
		}
	};


	// const showDeleteToast = (id) => {
	// 	toast((t) => (
	// 		<div className="flex flex-col items-center p-3 space-y-4">
	// 			<p className="text-md">Are you sure you want to Delete the Product?</p>
	// 			<div className="flex space-x-4">
	// 				<button
	// 					className="px-5 py-2 bg-[#754F23] text-white rounded"
	// 					onClick={() => {
	// 						handleDelete(id);
	// 						toast.dismiss(t.id);
	// 					}}
	// 				>
	// 					Delete
	// 				</button>
	// 				<button
	// 					className="px-5 py-2 bg-gray-300 rounded"
	// 					onClick={() => toast.dismiss(t.id)}
	// 				>
	// 					Cancel
	// 				</button>
	// 			</div>
	// 		</div>
	// 	), {
	// 		duration: 5000,
	// 		position: 'top-center',
	// 	});
	// };

	const openModal = (product) => {
		setSelectedProduct(product);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedProduct(null);
	};

	const openImageModal = (product) => {
		setSelectedProduct(product);
		setIsImageModalOpen(true);
	};

	const closeImageModal = () => {
		setIsImageModalOpen(false);
		setSelectedProduct(null);
	};


	const openOfferListModal = (product) => {
		setSelectedProduct(product);
		setIsOfferListModalOpen(true);
	};

	const closeOfferListModal = () => {
		setIsOfferListModalOpen(false);
		setSelectedProduct(null);
	};

	const openOfferModal = (product) => {
		setSelectedProduct(product);
		setIsOfferModalOpen(true);
	};

	const closeOfferModal = () => {
		setIsOfferModalOpen(false);
		setSelectedProduct(null);
	};

	return (
		<>
			<UpdateProductModal isOpen={isModalOpen} page={page} onClose={closeModal} product={selectedProduct} />
			<OfferListModal isOpen={isOfferListModalOpen} onClose={closeOfferListModal} id={selectedProduct} />
			<ImageUploadModal isOpen={isImageModalOpen} page={page} onClose={closeImageModal} product={selectedProduct} />
			<OfferModal isOpen={isOfferModalOpen} page={page} onClose={closeOfferModal} productId={selectedProduct} />
			<motion.div
				className='bg-white backdrop-blur-md shadow-lg rounded-xl px-6 pt-6 pb-3 mb-8'
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ delay: 0.2 }}
			>
				{loading && (
					<div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10 rounded-xl">
						<p className="text-gray-700">Loading...</p>
					</div>
				)}
				<div className='flex justify-between items-center mb-6'>
					<h2 className='text-xl font-normal text-black'>Products</h2>
					<div className='relative'>
						<input
							type='text'
							placeholder='Search Products...'
							className='bg-gray-100 border text-black placeholder-gray-400 rounded-lg pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#DB4444]'
							value={searchTerm}
							onChange={handleSearch}
						/>
						<Search className='absolute left-3 top-2.5 text-gray-400' size={18} />
					</div>
				</div>

				<div className='overflow-x-auto h-[500px] overflow-auto no-scrollbar'>
					<table className='min-w-full divide-y divide-gray-700'>
						<thead>
							<tr>
								<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>Name</th>
								<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>Category</th>
								<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>Price</th>
								<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>Stock</th>
								<th className='px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider'>Actions</th>
							</tr>
						</thead>
						<tbody className='divide-y divide-gray-300'>
							{filteredProducts.map((product) => (
								<motion.tr key={product.id} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
									<td className='px-6 py-4 whitespace-nowrap text-sm font-normal line-clamp-1 text-[#555F7E] flex gap-2 items-center'>
										<img src={product?.image} alt='Product img' className='size-10 rounded-full' />
										<span className="max-w-[200px]">{product?.productName}, {product?.color}</span>
									</td>
									<td className='px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]'>{product?.subCategoryId}, {product?.categoryId}</td>
									<td className='px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]'>₹{product?.price?.toFixed(2)}</td>
									<td className='px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]'>
										{product.inStock ? 'In Stock' : 'Out of Stock'}
									</td>
									<td className='px-6 py-4 whitespace-nowrap text-sm text-[#555F7E]'>
										<button onClick={() => openModal(product)} className='text-pink-400 hover:text-pink-300 mr-2'><Edit size={18} /></button>
										<button onClick={() => openImageModal(product)} className='text-indigo-400 hover:text-indigo-300 mr-2'><Image size={18} /></button>
										<button onClick={() => openOfferModal(product.id)} className='text-green-400 hover:text-green-300 mr-2'><Gift size={18} /></button>
										<button onClick={() => openOfferListModal(product.id)} className='text-red-400 hover:text-red-300 '><List size={18} /></button>
									</td>
								</motion.tr>
							))}
						</tbody>
					</table>
					{/* Loading more products indicator */}

				</div>
				<div className=" gap-2 flex justify-end pt-2 space-x-2">
					<button className='bg-[#754F23] text-white rounded px-2 hover:bg-[#754F23]/60' disabled={page === 0} onClick={handlePrev}>Prev</button>
					<button className='bg-[#754F23] text-white rounded px-2 hover:bg-[#754F23]/60' onClick={handleNext}>Next</button>
				</div>
			</motion.div>
		</>
	);
};

export default ProductsTable;
