import { motion } from "framer-motion";
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders, fetchOrdersById } from '../../../redux/slices/adminSlice';
import { updateOrderStatus } from "../../../services/adminServices";

const OrderModal = ({ isOpen, page, onClose, id }) => {
    const dispatch = useDispatch();
    const orderDetail = useSelector((state) => state.adminData.orderDetails.data);
    const loading = useSelector((state) => state.adminData.orderDetails.loading);
    const [status, setStatus] = useState('')

    useEffect(() => {
        if (id) {
            dispatch(fetchOrdersById(id));
        }
    }, [dispatch, id]);

    const handleSubmit = async () => {
        try {
            await toast.promise(
                updateOrderStatus(id, status),
                {
                    loading: 'Updating Status...',
                    success: (response) => `${response.message}`,
                    error: (err) => `${err.response.data.message}`,
                }
            );
            dispatch(fetchOrders(page))
        } catch (error) {
            console.log(error)
        }
        onClose();
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    if (!isOpen) return null;
    if (loading) {
        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <p className="text-gray-200 font-semibold text-2xl animate-pulse">Loading...</p>
            </div>
        );
    }

    return (
        <motion.div>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg shadow-xl w-full max-w-3xl p-6 md:p-10 relative overflow-hidden">
                    <button onClick={onClose} className="absolute top-4 right-4 text-gray-600 hover:text-gray-900">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>

                    <h2 className="text-3xl font-semibold mb-6 text-gray-800">Order Information</h2>

                    {/* Order Information */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 border-b pb-6 mb-6">
                        <p><span className="font-semibold text-gray-700">Order ID:</span> {orderDetail?.id?.toUpperCase()}</p>
                        <p><span className="font-semibold text-gray-700">Cart Value:</span> ₹{orderDetail?.finalCartvalue}</p>
                        <p><span className="font-semibold text-gray-700">Shipping Charges:</span> ₹{orderDetail?.shipingCharges}</p>
                        <p><span className="font-semibold text-gray-700">Payment Method:</span> {orderDetail?.paymentMethod?.toUpperCase()}</p>
                        <p><span className="font-semibold text-gray-700">Order Status:</span> {orderDetail?.order_statuses?.[0]?.status.toUpperCase() || 'N/A'}</p>
                        <p><span className="font-semibold text-gray-700">Order Date:</span> {new Date(orderDetail?.createdAt).toLocaleDateString()}</p>
                    </div>
                    <div className="my-6">
                        <label className="block font-semibold text-gray-700 mb-2">Order Status</label>
                        <select
                            value={status}
                            onChange={handleStatusChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#754F23]"
                        >
                            <option value="" disabled>Select Status</option>
                            <option value="processing">Processing</option>
                            <option value="delivered">Delivered</option>
                            <option value="cancelled">Cancelled</option>
                        </select>
                    </div>

                    {/* Order Items */}
                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Ordered Items</h3>
                    <div className="grid grid-cols-1 gap-6">
                        {orderDetail?.order_items?.map((item) => (
                            <div key={item.id} className="flex items-center bg-gray-50 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                                <img src={item.image} alt={item.productName} className="w-16 h-16 rounded-lg object-cover mr-4" />
                                <div className="flex-grow">
                                    <p className="font-semibold text-gray-800">{item.productName}</p>
                                    <p className="text-gray-600">Category: {item.categoryId}</p>
                                    <p className="text-gray-600">Color: {item.color} | Size: {item.size}</p>
                                    <p className="text-gray-800 font-semibold">₹{item.finalPrice} <span className="text-sm text-gray-500">(x{item.quantity})</span></p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Modal Actions */}
                    <div className="mt-8 flex justify-end space-x-4">
                        <button
                            className="bg-gray-200 text-gray-700 px-5 py-2 rounded-lg font-medium hover:bg-gray-300 transition-colors"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="bg-[#754F23] text-white px-5 py-2 rounded-lg font-medium hover:bg-[#754F23]/80 transition-colors"
                            onClick={handleSubmit}
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default OrderModal;
