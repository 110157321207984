import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const ImageModal = ({ isOpen, handleClose, imageSrc, altText }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            contentLabel="Image Modal"
            className="fixed inset-0 flex items-center justify-center h-screen z-[1050] outline-none"
            overlayClassName="fixed inset-0 bg-black bg-opacity-70 z-[1040]"
            ariaHideApp={false}
        >
            <div className="relative flex items-center justify-center">
                <button
                    onClick={handleClose}
                    className="absolute top-4 right-4 text-white transition-all bg-black bg-opacity-20 hover:bg-opacity-50 h-10 w-10 rounded-full"
                >
                    &times;
                </button>
                <img
                    src={imageSrc}
                    alt={altText}
                    className=" sm:max-w-md sm:max-h-lg object-contain"
                />
            </div>
        </Modal>
    );
};

export default ImageModal;
