import React from 'react'
import Sticker1 from '../assets/images/Miroodles Sticker.png'
import Sticker2 from '../assets/images/Miroodles Sticker (1).png'
import Sticker3 from '../assets/images/Miroodles Sticker (2).png'
import Sticker4 from '../assets/images/Miroodles Sticker (3).png'



const Banner = () => {
    return (
        <div className='bg-[#F2F2F2] sm:py-[50px] sm:px-[100px] px-10 py-10 font-PlayfairDisplay w-full'>
            <div className='text-center mb-[35px]'>
                <h3 className='text-4xl font-semibold text-[#2f2f2f] mb-[30px] sm:mb-[40px]'>Features</h3>
                <p className='text-[#555555] text-2xl font-normal leading-tight tracking-tight'>Making luxury abayas accessible to every woman, every day</p>
            </div>
            <div className='grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-2 mx-auto text-center text-[#555555] text-xl leading-tight gap-20'>
                <div>
                    <img src={Sticker3} alt="" className='sm:w-[90px]  w-[60px] h-[60px] sm:h-[67px] mx-auto mb-5' />
                    <p>Fast shipping.</p>
                </div>
                <div>
                <img src={Sticker4} alt="" className='w-[60px] h-[60px] sm:w-[90px] sm:h-[67px] mx-auto mb-5' />
                    <p>Premium materials and unique designs.</p>
                </div>
                <div>
                <img src={Sticker1} alt="" className='w-[70px] h-[70px] sm:w-[90px] sm:h-[67px] mx-auto mb-5' />
                    <p>Classic styles with a modern touch.</p>
                </div>
                <div>
                <img src={Sticker2} alt="" className='w-[60px] h-[60px] sm:w-[90px] sm:h-[67px] mx-auto mb-5' />
                    <p>Sustainable from start to finish.</p>
                </div>
            </div>
        </div>
    )
}

export default Banner
